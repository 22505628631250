.GiHamburgerMenu {
  font-size: 30px;
  color: var(--dashboard-main-color);
  position: absolute;
  top: 25px;
  left: 20px;
}

.sidebarDiv {
  width: 280px;
}

.contentDiv {
  width: calc(100% - 280px);
}

@media screen and (max-width: 1440px) {
  .sidebarDiv {
    width: 270px;
  }

  .contentDiv {
    width: calc(100% - 270px);
  }
}

@media screen and (max-width: 1220px) {
  .sidebarDiv {
    width: 260px;
  }

  .contentDiv {
    width: calc(100% - 260px);
  }
}

@media screen and (max-width: 1100px) {
  .sidebarDiv {
    width: 250px;
  }

  .contentDiv {
    width: calc(100% - 250px);
  }
}

@media (max-width:1024px) {
  .GiHamburgerMenu {
    top: 20px;
    left: 20px;
  }  
}
@media (max-width:370px) {
  .GiHamburgerMenu {
    font-size: 20px ;
    top: 15px;
    left: 20px;
  }  
}