.radioWithLabel {
  display: flex;
  align-items: center;
}

.radioWithLabel label {
  line-height: 1;
  margin-left: 8px;
  color: var(--text-color-black);
  font-size: 16px;
  /* font-family: "Open-Sans-semiBold" !important; */
  cursor: pointer;
  text-transform: capitalize;
}

.radioInput {
  accent-color: var(--secondary-color) !important;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.radioInput:hover {
  accent-color: var(--text-color-black) !important;
}

.labelDisabled {
  color: #dddddd;
}

.labelChecked {
}

.label {
}
