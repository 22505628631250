.detail p:first-child{
    margin: 0px !important;
    font-weight: 700;
}

.payBtn{
    border-radius: 46px;
    padding-block: 14px;
    width: max-content;
  }
  