.lessonCardMain {
  box-shadow: 0px 0px 10px 0px var(--primary-color);
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  background-color: var(--white-color);
  padding: 0;
  position: relative;
}

.selectedLessonMain {
  background: beige !important;
}

.lessonCardMain:hover {
  box-shadow: 0px 0px 20px 0px var(--secondary-color);
  cursor: pointer;
  transform: translateY(-5px);
}

.lessonDetails {
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 320px;
  height: max-content;
}

.lessonDetails h3 {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--secondary-color);
  text-transform: capitalize;
}
.lessonDescription {
  min-height: 200px;
}

.lessonDetails p {
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-dark-gray);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.lessonDetailsBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lessonDetailsBottomLeft {
  display: flex;
  align-items: center;
}

.lessonDetailsBottomLeft p {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--text-dark-gray);
  margin-left: 5px;
  margin-bottom: 0px !important;
  text-transform: capitalize;
}

.lessonDetailsBottomRight {
  display: flex;
  align-items: center;
}

.lessonDetailsBottomRight p {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--text-dark-gray);
  margin-right: 5px;
  margin-bottom: 0px !important;
  text-transform: capitalize;
}
