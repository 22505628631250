.textAreaBox {
  position: relative;
}

.label {
  color: var(--text-color);
  text-transform: capitalize;
  font-family: "Poppins-semiBold";
  font-size: 18px;
  margin-bottom: 2px;
}

.textAreaBox textarea {
  padding: 12px;
  font-size: 16px;
  font-family: "Poppins-regular";
  color: var(--text-color-black);
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  background-color: var(--input-clr);
  border-radius: 10px;
  box-shadow: 0px 0px 6px #00000029;
}

.textAreaBox textarea::placeholder {
  color: var(--placeholder-color);
  font-family: "Poppins-medium";
  font-size: 16px;
  text-transform: capitalize;
}

.textAreaBox textarea:disabled {
  background-color: var(--disabled-input-color);
  color: #c9c9c9;
  user-select: none;
}

.labelDisabled {
  color: var(--disabled-label-color);
}
