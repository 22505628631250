.modalClass {
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
}
.main {
  padding: 20px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.body {
  background-color: var(--white-color);
  border-radius: 20px;
}
.body p {
  margin-bottom: 0px;
  text-transform: capitalize;
}
.row {
  row-gap: 20px;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  border-radius: 46px;
  background-color: #e5e5e5;
  min-width: 273px;
  padding: 15px;
  /* box-shadow: 21px 14px 24px #654ACB30; */
  cursor: pointer;
}
.active {
  background: var(--primary-color);
}

.crossBtn {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.btnCenter {
  display: flex;
  justify-content: center;
}
.btnCenter a {
  text-decoration: none;
}

.pdfContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

@media screen and (max-width: 425px) {
  .btn {
    width: max-content;
    min-width: auto;
    padding: 10px 30px;
  }
}
