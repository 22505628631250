.topContainer {
  height: calc(100vh - 122px);
  overflow-y: auto;
}

.main {
  max-width: 96% !important;
  padding-block: 20px;
}
.title_design {
  margin-block: 20px;
  color: var(--primary-color);
}
