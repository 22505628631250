.addProjectModal_main {
  padding: 0 35px;
}
.addProjectModal_main h4 {
  text-align: center;
  margin-bottom: 40px;
  color: var(--text-color-black);
  font-family: "Open-Sans-bold";
}
.btn_main button {
  width: 200px;
}
.btn_main {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.btn {
  width: 100%;
  padding: 17px 0;
}
.addProject_row {
  row-gap: 30px;
}
.boxImageClass {
  object-fit: cover !important;
  object-position: center !important;
}
