.statWrap {
  box-shadow: 0px 10px 43px #00000012;
  background-color: var(--white-color);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;

  height: 140px;
  width: 100%;
  padding-left: 20px;
  position: relative;
  transition: background 0.5s ease-in-out;
  cursor: pointer;
  padding: 20px;
}

.statWrap p {
  font-size: 17px;
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 0px !important;
}

.odd_h3 {
  color: #e65d90;
  margin-bottom: 0px;
}

.even_h3 {
  color: #654acb;
  margin-bottom: 0px;
}

.cardImg {
  position: absolute;
  right: 0px;
  top: 0px;
}

@media screen and (max-width: 1600px) {
  .statWrap p {
    font-size: 16px;
  }

  .statWrap h4 {
    font-size: 30px;
  }
}
