.navbarContainer {
  display: flex;
  justify-content: space-between;
  padding-block: 10px;
  padding-inline: 45px;
  max-width: 100% !important;
  border-bottom: 1px dashed var(--primary-color);
}

.leftSide {
  flex-grow: 1;
  max-width: 65%;
  display: flex;
  align-items: center;
}
.leftSide .pageHeading {
  font-size: 24px;
  font-family: "Poppins-bold";
}

.rightSide {
  display: flex;
  align-items: center;
}
.iconDiv {
  display: flex;
  align-items: center;
  margin-right: 12px;
  user-select: none;
  cursor: pointer;
}

.notifyoverlayDiv {
  top: 10px !important;
  left: -90px !important;
  z-index: 2;
}

.notiIcon {
  width: 30px;
  height: 30px;
  background: transparent
    linear-gradient(
      138deg,
      var(--primary-color) 0%,
      var(--secondary-color) 100%
    )
    0% 0% no-repeat padding-box;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.notiCount {
  position: absolute;
  top: -8px;
  right: -2px;
  background-color: red;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.userWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.userWrap p {
  margin-bottom: 0px !important;
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
}

.profileImg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #d6d6d6;
  box-shadow: 7px 7px 22px #00000017;
  user-select: none;
}

.profileImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: top center;
  cursor: pointer;
}

@media screen and (max-width: 1600px) {
  .leftSide {
    max-width: 75%;
  }
}

@media (max-width: 1200px) {
  .gapCustm {
    gap: 1.5rem;
  }
}

@media (max-width: 1080px) {
  .gapCustm {
    gap: 1rem;
  }

  .profileImg {
    width: 50px;
    height: 50px;
  }
}

@media (max-width:1024px) {
  .leftSide {
   margin-left: 20px;
  }
 
  
}
@media (max-width:500px) {
  
  .leftSide .pageHeading {
    font-size: 20px;
  }
  .navbarContainer{
    padding-inline: 35px;
  }
  
}
@media (max-width:380px) {
  
  .leftSide .pageHeading {
    font-size: 16px;
  }
  .navbarContainer{
    padding-inline: 35px;
  }
  .userWrap {
   
    gap: 5px;
  }
  .profileImg {
    width: 35px;
    height: 35px;
    
  }
  
}
