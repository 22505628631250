.main {
  padding-block: 20px;
  max-width: 96% !important;
  height: calc(100vh - 121px);
  /* height: 800px; */
  overflow-y: scroll;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.statsSvgIcon {
  width: 40px;
  height: 40px;
}

.contentWrapper {
  box-shadow: 0px 10px 43px #00000012;
  border-radius: 20px;
  height: 450px;
  overflow-y: auto;
}
.contentWrapper .head {
  position: sticky;
  top: 0;
  background-color: var(--white-color);
  padding: 20px;
  margin-bottom: 0px;
}
.contentWrapper .head button {
  padding: 10px 20px;
}
.contentWrapper h6 {
  margin-bottom: 0px;
}
.contentDiv {
  padding: 10px 20px 20px 20px;
}
.statsIcon {
  color: var(--primary-color) !important;
}
.statsCol {
  flex-basis: calc(20% - 16px);
}
.statsWrap {
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
}

.row {
  row-gap: 20px;
}

.row h6 {
  font-family: "Poppins-semiBold";
}

.viewDetailBtn {
  /* background: transparent linear-gradient(90deg, #E65D90 0%, #654ACB 100%) 0% 0% no-repeat padding-box; */
  box-shadow: 21px 14px 24px #654acb30;
  border-radius: 46px;
  padding-block: 14px;
}

.actions_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.announcementCard {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px #a1303442;
  cursor: pointer;

  margin-bottom: 10px;
}
.announcementCard span {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 17px;
}
.announcementCard p {
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-bottom: 0px;
}
.usersAndRoles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.usersAndRoles p {
  font-weight: 600 !important;
  font-size: 14px;
}
.usersAndRoles span {
  margin-left: 10px;
  border: 1px solid var(--primary-color);
  text-transform: uppercase;
  border-radius: 10px;
  font-size: 14px;
  padding: 4px 10px;
}
.dates {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.dates label {
  font-size: 16px;
  font-weight: 700;
}
.dates span {
  font-weight: 600;
  color: var(--text-color-black);
  font-size: 15px;
}
/* Tasks Section */
.taskCard {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px #a1303442;
  cursor: pointer;
  margin-bottom: 10px;
}
.taskCard span {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 17px;
}
.taskCard p {
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  margin-bottom: 0px;
}
.skeleton{
  margin-bottom: 10px;
}
  