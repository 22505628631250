.main {
    padding-block: 20px;
    max-width: 96% !important;
    /* height: 90%; */
}

.notificationContainer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 200px);
    overflow-y: scroll;
    gap: 10px;
}


.content {
    /* height: calc(100vh - 121px); */
    /* overflow-y: scroll; */
}

.loader {
    min-height: calc(100vh - 255px)
}

.row {
    row-gap: 20px;
}
/* .tableMain{
    height: calc(100vh - 200px);
} */

.row h6 {
    font-family: 'Poppins-semiBold';
    font-size: 30px;
}

.btns {
    display: flex;
    gap: 10px;
}

.viewDetailBtn {
    background: transparent linear-gradient(90deg, #E65D90 0%, #654ACB 100%) 0% 0% no-repeat padding-box;
    box-shadow: 21px 14px 24px #654ACB30;
    border-radius: 46px;
    padding-block: 14px;
    width: 150px;
}

.uploadBtn {
    background: transparent linear-gradient(90deg, #E65D90 0%, #654ACB 100%) 0% 0% no-repeat padding-box;
    box-shadow: 21px 14px 24px #654ACB30;
    padding-block: 14px;
}

.spBtw {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.paginationDiv {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.actions_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

/* Notification Box */




.HeaderNotificationInnerDiv {
    display: flex;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid var(--secondary-color);
    transition: background 0.5s;
    position: relative;
}
.flag{
    position: absolute;
    /* background: transparent
    linear-gradient(90deg, var(--primary-color) 0%, var(--secondary-color) 100%)
    0% 0% no-repeat padding-box; */
    background: #ffa7a74a;
    border: 1px solid grey;
    /* color: #FFFFFF; */
    bottom: 7px;
    right: 7px;
    border-radius: 8px;
    padding: 3px 10px;
    text-transform: capitalize;
}
.HeaderNotificationInnerDiv[data-seen=true] {
    background-color: rgb(250 250 250);
}

.HeaderNotificationInnerDiv:hover {
    background: #ffa7a74a;
}

.HeaderNotificationInnerDiv .contentDiv {
    margin-left: 12px;
    width: 100%;
}

.HeaderNotificationInnerDiv .contentDiv h6 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-block: 6px;
    font-size: 18px;
}

.HeaderNotificationInnerDiv .contentDiv p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #8f8f8f;
    margin-bottom: 0px;
    font-size: 16px;
}

.titleDiv {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.ImgDiv {
    width: 80px;
    height: 80px;
    border-radius: 80px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c8c8c84a;
}







@media screen and (max-width:767px) {
    .ImgDiv {
        width: 70px;
        height: 70px;
        border-radius: 70px;
        font-size: 35px;
    }

}

@media screen and (max-width:575px) {
    .HeaderNotificationInnerDiv .titleDiv p {
        font-size: 14px;
        width: 100%;
        text-align: right;
    }

    .ImgDiv {
        width: 60px;
        height: 60px;
        border-radius: 60px;
        font-size: 30px;
    }

}

@media screen and (max-width:375px) {
    .HeaderNotificationInnerDiv .titleDiv p {
        font-size: 13px;
    }

    .ImgDiv {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        font-size: 25px;
    }

}