.main {
  max-width: 96% !important;
  padding-block: 20px;
  height: calc(100vh - 81px);
}
.row {
  row-gap: 20px;
}
.tableMain{
  height: calc(100vh - 190px);
  overflow-y: scroll;
}
.paginationDiv {
  display: flex;
  justify-content: flex-end;
}
